<template>
  <div class="instant-win-detail">
    <div class="form-content content">
      <ActionsForPreview
          :loading="loading"
          @edit="editInstantWin"
          @delete="openModal"
          :isEditEnabled="true"
      />
      <div v-if="ready">
        <CRow>
          <CCol col="8" class="">
            <CCard class="zq--wizard-card" v-if="ready">
              <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">{{ texts.previewPage.title }}</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="basicIsCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="basicIsCollapsed" :duration="400">
                <CCardBody>
                    <ZqFieldsView :texts="texts.previewPage" :formData="formData" :modelFields="modelFields" />
                </CCardBody>
              </CCollapse>
            </CCard>
          </CCol>
          <CCol col="4" class="d-flex p-0">
            <CCard class="zq--wizard-card w-100 flex-column justify-content-center align-items-center" v-if="ready">
                <Wheel
                  v-if="instantWinType === 1"
                  :sections-count="instantWinTiles.length"
                  :tilesData="instantWinTiles"
                  :isHideArrow="true"
                  :isVerticallyText="isVerticallyText"
                  :wheelSettings="wheelSettings"
                />
                <ScratchCard
                  v-if="instantWinType === 2"
                  :tilesData="instantWinTiles"
                  :tilesCount="instantWinTiles.length"
                  :cols="instantWinTiles[instantWinTiles.length - 1].location.col"
                  :rows="instantWinTiles[instantWinTiles.length - 1].location.row"
                  :previewMode="true"
                />
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="12">
            <CCard class="zq--wizard-card" v-if="ready">
              <CCardHeader @click="tilesIsCollapsed = !tilesIsCollapsed">
                <div class="d-flex justify-content-between">
                  <strong class="title">{{ texts.previewPage.tilesTitle }}</strong>
                  <CLink class="card-header-action btn-minimize">
                    <ClCardArrow :is-collapsed="tilesIsCollapsed" />
                  </CLink>
                </div>
              </CCardHeader>
              <CCollapse :show="tilesIsCollapsed" :duration="400">
                <InstantWinTileView v-if="instantWinTiles.length" :instantWinTileData="instantWinTiles" />
              </CCollapse>
            </CCard>
          </CCol>
        </CRow>

        <CelebrationMessagePreview :messageSettings="messageSettings" />

        <ViewTranslations
          v-if="modelFields.baseFields.translatableFields.length > 0"
          :entityData="formData"
          :entityId="formData.id"
          :translatableFields="modelFields.baseFields.translatableFields"
        />
      </div>
      <PreviewSpiner v-else />
    </div>
    <Modal
      :modalShow="deleteModal"
      :messageGeneral="texts.deleteMessage"
      :title="texts.deleteTitle"
      @doFunction="deleteInstantWin"
      v-on:toggle-modal="deleteModal = false"
    />
  </div>
</template>

<script>
import ClCardArrow from '@/shared/UI/ClCardArrow.vue';
import Modal from '@/shared/UI/Modal.vue';
import PreviewSpiner from '@/shared/UI/Spiner.vue';
import ActionsForPreview from '@/shared/components/ActionsForPreview.vue';
import ZqFieldsView from '@/shared/components/ZqFieldsView.vue';
import { mapActions, mapGetters } from 'vuex';
import { instantWinsTexts } from '@/config/pageTexts/instantWins.json';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import fields from '@/generated/ziqni/store/modules/instantWins/fields';
import InstantWinTileView from '@/shared/components/steps/components/instantWinTileView.vue';
import ViewTranslations from '@/shared/components/supportModels/translations/ViewTranslations.vue';
import Wheel from '@/views/ziqni/instant-wins/components/Wheel.vue';
import ScratchCard from '@/views/ziqni/instant-wins/components/ScratchCard.vue';
import CelebrationMessagePreview from '@/views/ziqni/instant-wins/CelebrationMessagePreview.vue';

export default {
  name: 'InstantWinDetails',
  components: {
    CelebrationMessagePreview,
    ScratchCard,
    Wheel,
    ViewTranslations,
    InstantWinTileView,
    ZqFieldsView,
    ActionsForPreview,
    PreviewSpiner,
    Modal,
    ClCardArrow
  },
  data() {
    return {
      model: 'instantWin',
      deleteModal: false,
      ready: false,
      basicIsCollapsed: true,
      tilesIsCollapsed: true,
      instantWinIsCollapsed : true,
      texts: {
        ...instantWinsTexts,
      },
      modelFields : {
        ...fields,
      },
      formData: {

      },
      instantWinTiles: [],
      instantWinType: 0,
      isVerticallyText: false,
      wheelSettings: {
        buttonText: `<p><strong class="ql-font-arial" style="color: #F4B41C;"><span class="ql-cursor"></span>SPIN</strong></p>`,
        wheelBackground: '#5E084B',
        spinButtonBackground: '#5E084B',
        icon: ''
      },
      messageSettings: null,
      fileObject: null,
      folderName: '/instant-wins',
      repositoryId: ''
    }
  },
  computed: {
    ...mapGetters('instantWins', [
      'success',
      'message',
      'instantWins',
      'loading',
      'instantWin'
    ]),
    ...mapGetters('theme', ['theme']),
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('instantWins', ['handleGetInstantWins_item', 'handleDeleteInstantWins']),
    ...mapActions('files', ['handleGetFileObjectsByQuery', 'handleDeleteFileObjects']),
    ...mapActions('fileRepositories', ['handleGetFileRepositoriesByQuery']),
    async initialize() {
      await this.handleGetInstantWins_item([this.$route.params.id],1,0).then((data) => {
        this.instantWinTiles = data[0].tiles;
        this.instantWinType = data[0].instantWinType;
        this.isVerticallyText = data[0].constraints.includes('isVerticallyText');

        routerBreadcrumbs(this.$router.currentRoute, {
          name: data[0].name
        });

        this.getInstantWinWheelSettings(data[0].id)

        if (data[0].scheduling) {
          this.schedulingData = data[0].scheduling;
        }

        this.ready = true;
      });

    },
    async getInstantWinWheelSettings(itemId) {
      const repository = await this.handleGetFileRepositoriesByQuery({
        queryRequest: {
          must: [{
            queryField: 'name',
            queryValues: ['system-resources'],
          }]
        }
      });

      this.repositoryId = repository[0].id;

      const sortBy = [{
        queryField: 'name',
        order: 'Asc',
      }];

      const query = {
        queryRequest: {
          must: [
            {
              queryField: 'repositoryId',
              queryValues: [repository[0].id],
            },
            {
              queryField: 'parentFolderPath',
              queryValues: [this.folderName],
            },
            {
              queryField: 'name',
              queryValues: [itemId],
            },
          ],
          sortBy: sortBy,
          limit: this.itemsPerPage,
          skip: 0
        }
      };
      const file = await this.handleGetFileObjectsByQuery(query)

      if (file[0] && file[0].uri) {
        this.fileObject = file[0];
        const fileUri = file[0].uri;

        fetch(fileUri)
          .then(response => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then(data => {
            this.wheelSettings = data.wheelSettings;
            this.messageSettings = data.messageSettings;
          })
          .catch(error => {
            console.error('Fetch error:', error);
          });
      }
    },
    editInstantWin() {
      this.$router.push({
        name: 'EditInstantWin',
        params: {
          id: this.formData.id,
        },
      });
    },
    openModal() {
      this.deleteModal = true;
    },
    async deleteInstantWin() {
      this.deleteModal = false;

      if (this.fileObject) {
        await this.handleDeleteFileObjects({idArray: [this.fileObject.id]});
      }

      this.handleDeleteInstantWins({idArray: [this.formData.id]})
        .then(() => {
          this.ready = false
          setTimeout(() => {
            this.$router.push({ name: 'InstantWins' })
          }, 1500)
        })
        .catch((e) => {console.log(e.message)});
    }
  },
  watch: {
    instantWin: {
      deep: true,
      handler: function (val) {
        this.formData = val;
      },
    },
  }
};
</script>

<style scoped lang="scss">
.instant-win-detail {
  height: 100%;
  flex: 2;
  .content {
    border-top: 1px solid var(--zq-content-border-color);
  }
  .form-content {
    height: calc(100% - 50px);
    background: var(--zq-sub-bg);
  }
  .lang-custom-button {
    width: 100%;
  }
}

.detail-label {
  @media (min-width: 420px) {
    max-width: 220px;
  }
}

.overview-label {
  font-style: italic;
  color: darkgray;
}
</style>